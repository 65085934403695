import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { AppState } from '../../../reducers'
import { RemoteTeacherListState } from '../../../reducers/remoteTeacher/remoteTeacherList'
import { fetchTeachers } from '../../../actions/remoteTeacher/remoteTeacherList'
import RemoteTeacherListTable from '../../../components/RemoteTeacher/RemoteTeacherListTable'

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { FiltersBar } from '../../components/Utils/Filters/Filter'
import { FilterGroup, FilterLevel, FilterMultipleRemoteTeacher, FilterReferent } from '../../components/Utils/Filters/index';
import { upsertRemoteTeacher } from '../../../actions/remoteTeacher/remoteTeacherUpsert'
import RemoteTeacherEditDialog from '../../../components/RemoteTeacher/RemoteTeacherEditDialog'
import RemoteTeacher, { RemoteTeacherInput } from '../../../models/RemoteTeacher'
import { pushAlert } from '../../../actions/alert'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(3),
    }
  }),
);

interface Props {
  remoteTeacherList: RemoteTeacherListState;
}

const RemoteTeacherList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedRemoteTeacher, setSelectedRemoteTeacher] = useState<RemoteTeacher & RemoteTeacherInput | null>(null)

  const handleUpdateRemoteTeacher = (dataRemoteTeacher: RemoteTeacherInput) => {
    if (selectedRemoteTeacher) {
      dispatch(upsertRemoteTeacher({ id: selectedRemoteTeacher.id, ...dataRemoteTeacher }, 
         () => dispatch(fetchTeachers(history.location.search)),
         (err: Error) => dispatch(pushAlert(`Error ${err.message} actualizando al Docente Remoto`, true))))
      setSelectedRemoteTeacher(null)
    }
  }

  useEffect(() => {
    dispatch(fetchTeachers(history.location.search));
  }, [dispatch, history.location.search]);


  return (
    <div>
      <FiltersBar >
        <FilterMultipleRemoteTeacher controlledLength={3}/>
        <FilterMultipleRemoteTeacher controlledLength={3} label='Responsable de Gestión' name='managementManager' />
        <FilterReferent/>
        <FilterGroup/>
        <FilterLevel/>
      </FiltersBar>

      <RemoteTeacherListTable
        rows={(props.remoteTeacherList.teachers || []).map(s => ({ data: s }))}
        loading={props.remoteTeacherList.isFetching}
        pagination={props.remoteTeacherList.pagination || undefined}
        showEditButton={true}
        onClickEdit={row => setSelectedRemoteTeacher(row.data as RemoteTeacher & RemoteTeacherInput)}
      />

      <Fab color="primary" className={classes.fab} onClick={() => history.push('/admin/remote-teachers/create', { title: 'Crear docentes remotos' })}>
        <AddIcon />
      </Fab>

      {selectedRemoteTeacher &&
        <RemoteTeacherEditDialog
          teacher={selectedRemoteTeacher}
          onDismiss={() => setSelectedRemoteTeacher(null)}
          onSubmit={handleUpdateRemoteTeacher}
        />

      }
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  remoteTeacherList: state.remoteTeacherList
})

export default connect(mapStateToProps, null)(RemoteTeacherList)