import React from 'react'
import { isNil } from 'ramda'
import { FilterTextField, FilterSelector, FilterDate, WeekFilterButton, currentFromFilter, FilterTime, FilterAutocompleteField, FilterAutocompleteMultipleField } from './Filter'
import { weekdayToDescription } from '../../../../utils/days'
import { classStatuses, statusToDescription as classStatusToDescription, reasonToDescription, cancellationReasons, kinds, kindsToDescription } from '../../../../models/Class'
import { statusToDescription as assignationStatusToDescription } from '../../../../models/Assignation'
import { departments } from '../../../../models/School'
import { ADMIN, Role } from '../../../../models/User'
import { ButtonGroup } from '@material-ui/core'
import moment from 'moment'
import RemoteTeacher from '../../../../models/RemoteTeacher'
import fetchApi, { getToken } from '../../../../utils/fetchApi'

export const searchTeachers = (search: string) =>
  fetchApi(getToken(), 'GET', `remote-teachers?omnisearch=${search}`)
    .then(response => response.teachers)

export const searchUniqueTeachers = (param: string, search: string) =>
  fetchApi(getToken(), 'GET', `remote-teachers?omnisearch=${search}`)
    .then(response => response.teachers.reduce((acc, current) => {
      if (!acc.some(teacher => teacher[param] === current[param])) {
        acc.push( current );
      }
      return acc;
    }, []))

//const b = [...new Set(a.map(item => item.manager))].map(manager => ({ manager }));


export const FilterDocumentNumber = (props) => <FilterTextField name="documentNumber" label="DNI" {...props} />
export const FilterFullName = (props) => <FilterAutocompleteField<RemoteTeacher>
  {...props}
  label='Nombre'
  name='fullName'
  search={searchTeachers}
  multiple
  selectLabel={teacher => `${teacher.fullName}`}
/>

export const FilterMultipleRemoteTeacher = (props) => <FilterAutocompleteMultipleField<RemoteTeacher>
  {...props}
  label={props.label || 'Nombre'}
  name={props.name || 'fullName'}
  search={(input) => searchUniqueTeachers(props.name || 'fullName', input)}
  selectLabel={teacher => `${teacher[props.name || 'fullName']}`}
/>

export const FilterGrade = (props) => <FilterTextField name="grade" label="Grupo" {...props} />
export const FilterGroup = (props) => <FilterTextField name="group" label="Grupo" {...props} />
export const FilterReferent = (props) => <FilterTextField name="referent" label="Referente" {...props} />
export const FilterLevel = (props) => <FilterTextField name="level" label="Nivel" {...props} />
export const FilterPosition = (props) => <FilterTextField name="position" label="Cargo" {...props} />
export const FilterRue = (props) => <FilterTextField name="rue" label="RUEE" {...props} />
export const FilterSchoolName = (props) => <FilterTextField name="schoolName" label="Nombre" {...props} />
export const FilterSchoolNumber = (props) => <FilterTextField name="schoolNumber" label="Nro Escuela" {...props} />

export const FilterCancellationReason = (props) => <FilterSelector name="cancellationReason" label="Cancelación" {...props}
  textAdapter={reasonToDescription}
  menuItems={cancellationReasons}
/>

export const FilterKind = (props) => <FilterSelector name="kind" label="Tipo"  {...props}
  textAdapter={kindsToDescription}
  menuItems={kinds}
  multiple
/>

export const FilterDepartment = (props) => <FilterSelector name="department" label="Departamento" {...props}
  menuItems={departments}
  multiple
/>

export const FilterSendToCeibal = (props) => {
  const value = props.filters[props.name]
  return <FilterSelector name="shouldSendToCeibal" label="En SVC"  {...props}
    value={isNil(value) ? "" : value === "Sí" ? "false" : "true"}
    textAdapter={(_value) => _value === "false" ? "Sí" : "No"} // It is in SVC if it shouldn't send to ceibal*/
    menuItems={['false', 'true']}
    multiple
  />
}
export const FilterClassStatus = (props) => <FilterSelector name="status" label="Estado"  {...props}
  textAdapter={classStatusToDescription}
  menuItems={classStatuses}
/>

export const FilterAssignationStatus = (props: { role: Role }) => <FilterSelector name="status" label="Estado"  {...props}
  textAdapter={assignationStatusToDescription}
  multiple
  menuItems={props.role === ADMIN ?
    [
      "assignation-pending",
      "school-notification-pending",
      "school-confirmation-pending",
      "remote-teacher-re-assignation-pending",
      "remote-teacher-notification-pending",
      "school-reschedule-pending",
      "remote-teacher-confirmation-pending",
      "classroom-teacher-confirmation-pending",
      "assigned",
      "unified",
      "dropped"
    ] :
    [
      "remote-teacher-confirmation-pending",
      "classroom-teacher-confirmation-pending",
      "assigned"
    ]
  }
/>

export const FilterWeekday = (props) => <FilterSelector name="weekday" label="Día"  {...props}
  textAdapter={weekdayToDescription}
  menuItems={['1', '2', '3', '4', '5']} // range(1,6)
  multiple
/>

export const FilterFromDate = (props) => <FilterDate name="from" label="Desde" timeCorrector={date => moment(date).startOf('day')}{...props} />
export const FilterToDate = (props) => <FilterDate name="to" label="Hasta" timeCorrector={date => moment(date).endOf('day')} {...props} />

export const FilterClassTime = (props) => <FilterTime name="time" label="Horario" {...props} />

export const WeekFilterButtonsBar = () => {
  return (
    <ButtonGroup color="primary" variant="outlined" aria-label="outlined primary button group">
      <WeekFilterButton from={currentFromFilter().subtract(1, 'week')} text='Semana anterior'></WeekFilterButton>
      <WeekFilterButton from={moment()} text='Semana actual'></WeekFilterButton>
      <WeekFilterButton from={currentFromFilter().add(1, 'week')} text='Semana siguiente'></WeekFilterButton>
    </ButtonGroup>
  )
}