import requestAction from '../../utils/requestAction'
import RemoteTeacher, { RemoteTeacherInput } from '../../models/RemoteTeacher';

export const upsertRemoteTeacher = (teacher: (RemoteTeacher|RemoteTeacherInput), handleResponse?: (response: any) => void, handleError?: (error: Error) => void) =>
  requestAction('REMOTE_TEACHER_UPSERT')(
    `remote-teachers${(teacher as RemoteTeacher).id ? `/${(teacher as RemoteTeacher).id}` : ''}`,
    (teacher as RemoteTeacher).id ? 'PUT' : 'POST',
    teacher,
    (_dispatch, _getState, response) => { if (response && handleResponse) handleResponse(response) },
    ( _dispatch, err ) => { if( err && handleError ) handleError( err ) })
