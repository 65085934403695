import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../reducers'
import { JobNotifications } from '../models/JobNotifications'
import NavItem from './Layout/NavItem'
import { GetAppOutlined, HourglassEmptyOutlined } from '@material-ui/icons'

interface DownloadReportNavItemProps {
  base: string
  jobs?: JobNotifications

}

const Notification = ({ completed, pending }: { completed: number, pending: number }) =>
  <>
    {pending !== 0 && <><HourglassEmptyOutlined style={{ fontSize: '16px' }} />&nbsp;{`${pending}`}</>}
    {pending !== 0 && completed !== 0 && <>&nbsp;/&nbsp;</>}
    {completed !== 0 && <><GetAppOutlined style={{ fontSize: '18px' }} />&nbsp;{`${completed}`}</>}
  </>

const DownloadReportNavItem = (props: DownloadReportNavItemProps) => {
  const completed = props.jobs?.completed || 0
  const pending = props.jobs?.pending || 0

  return <NavItem
    uri='download'
    title='Descarga de Reportes'
    icon='get_app'
    notification={pending !== 0 || completed !== 0 ? <Notification completed={completed} pending={pending} /> : undefined}
    base={props.base}
    nested
  />
}

const mapStateToProps = (state: AppState) => ({
  jobs: state.jobNotifications.jobNotifications
})

export default connect(mapStateToProps, null)(DownloadReportNavItem)