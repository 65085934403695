import React from 'react'
import useExternalScript from '../utils/externalScript'
import { connectApp, AppProp } from '../reducers'
import { isAdmin, isLogged } from '../reducers/auth/session'

const CeiBot: React.FC<AppProp<'session'>> = ({ session }) => {
  React.useEffect(() => {
    const dfMessenger = document.querySelector('df-messenger');

    const stylingMessenger = () => {
      // obtiene el boton para cambiar la posicion
      const widgetIconElement = dfMessenger?.shadowRoot?.querySelector('.df-messenger-wrapper')?.querySelector('#widgetIcon');
      (widgetIconElement as HTMLElement).style.right = "200px";
      (widgetIconElement as HTMLElement).style.bottom = "11px";
      // obtiene el cuadro de chat para cambiar la altura
      const messengerChat = dfMessenger?.shadowRoot?.querySelector("df-messenger-chat")?.shadowRoot?.querySelectorAll('style')[0];
      if (messengerChat) 
        (messengerChat as HTMLElement).innerText = (messengerChat as HTMLElement).innerText.replace('height: 560px;','height: 450px;')
    };
    
    dfMessenger?.addEventListener('df-messenger-loaded', stylingMessenger);

    return () => {
      dfMessenger?.removeEventListener('df-messenger-loaded', stylingMessenger);
    };
  }, []);

  useExternalScript( { url: "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"} )

  const shouldShow = isLogged(session) && !isAdmin(session)
  
  return ( shouldShow ) ? (
          <div dangerouslySetInnerHTML={{__html: `
          <df-messenger 
          chat-icon="https:&#x2F;&#x2F;yuricaminos.com.ar&#x2F;wp-content&#x2F;uploads&#x2F;2022&#x2F;04&#x2F;zorro-2.png"
          intent="WELCOME"
          wait-open="true"
          chat-title="Ceibot"
          agent-id="c2b102e5-48ab-40f7-a9c1-b82c0a114cdc"
          language-code="es">
          </df-messenger>
          <style>
          df-messenger {
            --df-messenger-button-titlebar-color: #7986cb; 
          }
          </style>`}}/> 
        ): <></>
}

export default connectApp('session')(CeiBot)