import React, { useState } from 'react';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { RemoteTeacherInput } from '../../models/RemoteTeacher';
import { getAttribute, appendAttribute } from '../../utils/csvParser';
import { CSV_RT_FIELDS } from '../../views/Administrator/RemoteTeacherCsvCreation/index';
import Selector from '../Selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: 'right',
      marginLeft: theme.spacing(1)
    }
  })
);

interface Props {
  teacher: RemoteTeacherInput;
  onDismiss: () => void;
  onSubmit: (teacher: RemoteTeacherInput) => void;
}

const RemoteTeacherEditDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [teacher, setTeacher] = useState<RemoteTeacherInput>(props.teacher);

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={true}
      onClose={props.onDismiss}
    >
      <DialogTitle>Editar docente remoto</DialogTitle>
      <DialogContent dividers>
        {CSV_RT_FIELDS.map(field =>
          !field.boolean ?
            <TextField
              key={field.name}
              label={field.display}
              margin="normal" variant="filled" required fullWidth
              defaultValue={getAttribute(field.name, teacher)}
              onChange={e => setTeacher(appendAttribute({ ...teacher }, field.name, e.target.value))}
            /> :
            < Selector
              menuItems={['No', 'Si']}
              label={field.display}
              key={field.name}
              onChange={e => setTeacher(appendAttribute({ ...teacher }, field.name, e.target.value === "Si" ? true : false))}
              value={getAttribute(field.name, teacher) ? "Si" : "No"}
              variant={"filled"}
            />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => props.onSubmit(teacher)}
        >
          Guardar
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={props.onDismiss}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoteTeacherEditDialog