import requestAction from '../../utils/requestAction'
import Job from '../../models/Job';

export const upsertJob = (job: (Job)) =>
  requestAction('JOB_UPSERT')(
    `jobs${job.jobId ? `/${job.jobId}` : ''}`,
    job.jobId ? 'PUT' : 'POST',
    job)

export const deleteJob = (jobId: string) =>
  requestAction('JOB_DELETE')(
    `jobs/${jobId}`,
    'DELETE')
