import React, { useState, FormEvent, useEffect } from 'react';
import { generate } from 'shortid';
import { compose, not } from 'ramda';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers/index';
import { AvailabilitySlotsState } from '../../../reducers/availabilitySlots';
import { fetchAvailabilitySlots, updateAvailabilitySlots } from '../../../actions/availabilitySlotList';
import { fetchConfig } from '../../../actions/config';
import { ConfigState } from '../../../reducers/config';
import { AvailabilitySlot, slotsTime, sameSlot, toSlotString } from '../../../models/AvailabilitySlot';
import { TableHead, TableRow, TableCell, TableBody, Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import AnahiTable from '../../components/Utils/AnahiTable';
import ProgressButton from '../../components/Utils/ProgressButton';
import useStyles from './styles';
import { days } from '../../../utils/days';
import InfoCard from '../../components/Utils/InfoCard';
import moment from 'moment';

interface Props {
  availabilitySlots: AvailabilitySlotsState;
  config: ConfigState;
}

const TeacherAvailabilitySlots: React.FC<Props> = ({ availabilitySlots, config }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [slots, setSlots] = useState<AvailabilitySlot[]>(availabilitySlots.availabilitySlots || [])
  const [changed, setChanged] = useState(false)

  const availabilityUpdateDate = availabilitySlots.availabilityUpdateDate ? moment(availabilitySlots.availabilityUpdateDate).format("DD [de] MMMM") : "Nunca"

  useEffect(() => {
    dispatch(fetchAvailabilitySlots)
    dispatch(fetchConfig)
  }, [dispatch])

  useEffect(() => {
    if (availabilitySlots.availabilitySlots)
      setSlots(availabilitySlots.availabilitySlots)
  }, [availabilitySlots.availabilitySlots])

  const isSlotAssigned = (slot: AvailabilitySlot) => availabilitySlots.slotsOccupied && availabilitySlots.slotsOccupied.some(sameSlot(slot))
  const includesSlot = (slot: AvailabilitySlot) => slots.some(sameSlot(slot))
  const selectSlot = (slot: AvailabilitySlot) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true)
    event.target.checked
      ? setSlots([slot, ...slots])
      : setSlots(slots.filter(compose(not, sameSlot(slot))))
  }
  const tooltipDescription = (slot: AvailabilitySlot): string => {
    if (!config.config || !config.config.availabilityUpdateEnabled) return "Los administradores han deshabilitado la edición"
    if (isSlotAssigned(slot)) return "Ya tenés un grupo asignado en este horario"
    if (includesSlot(slot)) return "Click para eliminar"
    return "Click para agregar"
  }

  const sendAvailability = (event: FormEvent) => {
    event.preventDefault()
    dispatch(updateAvailabilitySlots(slots))
    setChanged(false)
  }

  const enoughSlotsSelected = () => config.config && config.config.minLegalSlots! <= slots.length

  if (!availabilitySlots.availabilitySlots) return (
    <div className={classes.fetching}>
      <CircularProgress size={100}></CircularProgress>
    </div>
  )

  return (<>
    <AnahiTable aria-label="availability" size="small">
      <TableHead>
        <TableRow selected={true}>
          <TableCell key={'time'}>Horario</TableCell>
          {days.map(day => <TableCell key={day}>{day}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {slotsTime.map(({ since: sinceTime, until: untilTime }, slotTime) =>
          <TableRow hover={true} key={sinceTime + untilTime}>
            <TableCell>{toSlotString(slotTime)}</TableCell>
            {days.map((_day, dayOfWeek) =>
              <TableCell key={generate()}>
                <Tooltip title={tooltipDescription({ dayOfWeek, slotTime })} >
                  <div> {/* This div makes the tooltip appear even if checkbox is disabled */}
                    <Checkbox
                      disabled={!config.config || !config.config.availabilityUpdateEnabled || isSlotAssigned({ dayOfWeek, slotTime })}
                      checked={includesSlot({ dayOfWeek, slotTime })}
                      onChange={selectSlot({ dayOfWeek, slotTime })}
                    />
                  </div>
                </Tooltip>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </AnahiTable>
    <form onSubmit={sendAvailability}>
      <InfoCard>
        <b>Mientras más slots se elijan, más posibilidades habrá de llegar a cubrir el máximo de horas deseado.</b>
        {!enoughSlotsSelected() &&
          <div>
            <b>Se deben elegir mínimo {config.config ? config.config.minLegalSlots : ""} horarios.</b>
          </div>
        }
      </InfoCard>
      <InfoCard>
        <b>Fecha de última actualización:</b> {availabilityUpdateDate}
      </InfoCard>
      <ProgressButton state={availabilitySlots} disabled={!enoughSlotsSelected()}>
        {changed ? 'Guardar cambios' : 'Reconfirmar Disponibilidad Horaria'}
      </ProgressButton>
    </form>
  </>
  )
}


const mapStateToProps = (state: AppState) => ({
  availabilitySlots: state.availabilitySlots,
  config: state.config
})

export default connect<Props, {}, {}, AppState>(mapStateToProps, {})(TeacherAvailabilitySlots)

