import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core"
import { identity, isEmpty, not } from "ramda"
import React, { FC } from "react"

export interface SelectorProps {
    className?: string
    disabled?: boolean
    fullwidth?: boolean
    hidden?: boolean
    helperText?: string
    margin?: "none" | "dense" | "normal"
    label?: string
    onChange?: (event: any) => void
    required?: boolean
    textAdapter?: ((value: any) => string)
    value?: any
    variant?: "filled" | "standard" | "outlined"
    menuItems?: any[]
    multiple?: boolean
    style?: any
}

const Selector: FC<SelectorProps> = ({ className = "", disabled = false, fullwidth = true, hidden = false, helperText = "", margin = "normal", label = "",
    onChange, required = false, textAdapter = identity, value = [], variant = "standard", menuItems = [], multiple = false, style }) =>   
    <div className={className}>
        {not(hidden) && <FormControl fullWidth={fullwidth} margin={margin} variant={variant} style={style}>
            <InputLabel required={required}>{label}</InputLabel>
            <Select disabled={disabled} multiple={multiple} 
                value={ value === undefined || value === null ? '' : typeof value === 'string' ? value.split(',') : value } onChange={onChange}>
                {menuItems.map(((item) => <MenuItem value={item} key={textAdapter(item)} style={{ fontWeight: (multiple && value && value.includes(item)) ? 800 : 400 }}>{textAdapter(item)}</MenuItem>))}
            </Select>
            {not(isEmpty(helperText)) && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>}
    </div>

export default Selector