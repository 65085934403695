import React from 'react'
import Divider from '@material-ui/core/Divider'
import RemoteTeacherWrapper from '../RemoteTeacherWrapper'
import RemoteTeacherNavItem from './RemoteTeacherNavItem'
import ReferentNavItems from '../../Referent/NavItems/ReferentNavItems'
import { defaultFilters } from '../../components/ClassesList'
import { filtersToSearch } from '../../components/Utils/Filters/Filter'
import DownloadReportNavItem from '../../DownloadReportNavItem'

const RemoteTeacherNavItems: React.FC = () =>
  <RemoteTeacherWrapper>
    <RemoteTeacherNavItem
      uri='dashboard'
      title='Home'
      icon='dashboard'
    />

    <RemoteTeacherNavItem
      uri='assignations'
      title='Mis asignaciones'
      icon='school'
    />

    <RemoteTeacherNavItem
      uri={`classes?${filtersToSearch(defaultFilters)}`}
      title='Mis clases'
      icon='event'
    />
    
    <DownloadReportNavItem base='teacher' />
    
    <RemoteTeacherNavItem
      uri='availability'
      title='Disponibilidad Horaria'
      icon='alarm-on'
    />

    <RemoteTeacherNavItem
      uri='non-school-days'
      title='Fechas importantes'
      icon='today'
    />

    <ReferentNavItems />

    <Divider />

    <RemoteTeacherNavItem
      uri='profile'
      title='Mi perfil'
      icon='person'
    />

    <RemoteTeacherNavItem
      uri='tutorials'
      title='Tutoriales'
      icon='collections-bookmark'
    />
  </RemoteTeacherWrapper>


export default RemoteTeacherNavItems