import React, { useState, FormEvent, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Paper, Container, Typography } from '@material-ui/core'
import ProgressButton from '../../components/Utils/ProgressButton'
import { entityTextField } from '../../components/Utils/EntityField'
import { AppState } from '../../../reducers/index'
import { fetchProfile, updateProfile } from '../../../actions/profile'
import { ProfileState } from '../../../reducers/profile'
import { Profile } from '../../../models/Profile'
import useStyles from './styles'
import { literalStrings } from '../../../utils/StringUtils';

interface Props {
  profile: ProfileState
}

const empty: Profile = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  documentNumber: '',
  documentType: '',
  id: ''
}

const PersonalInformation: React.FC<Props> = ({ profile }) => {
  const classes = useStyles()
  const [changed, setChanged] = useState<boolean>(false)
  const [personalInformation, setPersonalInformation] = useState<Profile>(profile.profile || empty)

  const change = (newPersonalInformation: Profile) => {
    setPersonalInformation(newPersonalInformation)
    setChanged(true)
  }

  const dispatch = useDispatch()
  const changeProfile = (event: FormEvent) => {
    event.preventDefault()
    dispatch(updateProfile(personalInformation))
    setChanged(false)
  }

  useEffect(() => {
    dispatch(fetchProfile)
  }, [dispatch])

  useEffect(() => {
    if (profile.profile)
      setPersonalInformation(profile.profile)
  }, [profile.profile])

  const PersonalInformationField = useMemo(() => entityTextField(change), [])

  return (
    <Container>
      <Paper square className={classes.paper}>
        <Typography variant="h5">
          Mis Datos
        </Typography>
        <form onSubmit={changeProfile}>
          {[
            literalStrings("Nombre", "firstName"),
            literalStrings("Apellido", "lastName"),
            literalStrings("Teléfono", "phoneNumber"),
            literalStrings("Documento", "documentNumber"),
          ].map(([label, attr], i) =>
            <PersonalInformationField
              key={i}
              variant="outlined"
              label={label}
              entity={personalInformation}
              attr={attr}
              required
              disabled
            />)
          }
          <ProgressButton state={profile} disabled={!changed}>
            Guardar
          </ProgressButton>
        </form>
      </Paper>
    </Container>

  )
}

const mapStateToProps = (state: AppState) => ({
  profile: state.profile
})

export default connect<Props, {}, {}, AppState>(mapStateToProps, {})(PersonalInformation)
