import { concat, isEmpty, isNil, without } from "ramda"
import Class, { CancellationReason } from "."
import { classStatuses } from '.';

export abstract class ClassStatusState {

    clazz: Class

    constructor(theClass: Class) {
        this.clazz = theClass
    }

    abstract isValid(): boolean

    cancellationReasons(): CancellationReason[] {
        return []
    }

    requiresTechNotes(): boolean {
        return false
    }

    requiresScreenshot(): boolean {
        return false
    }

    needsTimeConfirmation(): boolean {
        return false
    }

    requiresEvidence(): boolean {
        return false
    }

}

export class Pending extends ClassStatusState {

    isValid() {
        return classStatuses.includes(this.clazz.status)
            && !isNil(this.clazz.sinceDate) && this.clazz.sinceDateMoment().isValid()
    }

}

export class Dictated extends Pending {

    isValid() {
        return (super.isValid()
            && hasContent(this.clazz.platformId)
            && hasContent(this.clazz.proposalId)
            && hasContent(this.clazz.phase)
            && hasContent(this.clazz.activityWasConcluded)
            && (hasContent(this.clazz.technicalNotes) || !this.requiresTechNotes())
            && hasContent(this.clazz.pedagogicalNotes)
            && (hasContent(this.clazz.screenshot) || !this.requiresScreenshot())
            && hasContent(this.clazz.agreements)
        )
    }

    requiresTechNotes() {
        return !!this.clazz.hadIssuesDuringTheCall
    }

    requiresScreenshot(): boolean {
        return hasContent(this.clazz.platform) && this.clazz.platform!.requiresScreenshot
    }

    needsTimeConfirmation() {
        return true
    }

}

export abstract class Cancelled extends ClassStatusState {

    isValid() {
        return hasContent(this.clazz.status)
            && hasContent(this.clazz.cancellationReason)
            && hasContent(this.clazz.agreements)
            && (hasContent(this.clazz.technicalNotes) || !this.requiresTechNotes())
            && (hasContent(this.clazz.screenshot) || !this.requiresEvidence())
    }

    requiresTechNotes() {
        return !!this.clazz.cancellationReason?.startsWith('TechnicalIssues')
    }

}

export class CancelledByRemoteTeacher extends Cancelled {

    cancellationReasons(): CancellationReason[] {
        return [
            'TechnicalIssuesRT',
            'HealthOrPersonalIssuesRT',
            'NonSchoolDay'
        ]
    }

}

export class CancelledWithAnticipation extends Cancelled {

    cancellationReasons(): CancellationReason[] {
        return [
            'TechnicalIssuesConnectivity',
            'TechnicalIssuesVideoConference',
            'TechnicalIssuesUnknown',
            'ExtracurricularActivity',
            'HealthOrPersonalIssuesCT',
            'Strike',
            'VideoConferenceRoomBusy',
            'BiweeklyGroup',
            'Holiday'
        ]
    }

}

export class CancelledWithoutAnticipation extends Cancelled {

    cancellationReasons(): CancellationReason[] {
        return without('BiweeklyGroup' as any, concat(new CancelledWithAnticipation(this.clazz).cancellationReasons(),
            [
                'NoAnswer',
                'RackKeyNotFound',
            ]))
    }

    requiresEvidence(): boolean {
        return true
    }
}

export class CancelledByExternalFactors extends Cancelled {

    cancellationReasons(): CancellationReason[] {
        return [
            'PowerCutAtSchool',
            'WorksAtSchool',
            'ClimateFactorsOrFewStudentsInTheClassroom',
            'Incident'
        ]
    }

    requiresEvidence(): boolean {
        return true
    }

}

export const hasContent = (field) => !isNil(field) && !isEmpty(field)