import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import RemoteTeacher from '../../../models/RemoteTeacher'
import Assignation from '../../../models/Assignation'
import EditButtonDialog from '../Utils/Dialogs/EditButtonDialog'
import ConfirmDialog from '../Utils/Dialogs/ConfirmDialog'
import { updateAssignation } from '../../../actions/assignation/assignationUpdate'
import { AppState } from '../../../reducers'
import { RemoteTeacherListState } from '../../../reducers/remoteTeacher/remoteTeacherList'
import { fetchTeachersOrderedBySuggestion } from '../../../actions/remoteTeacher/remoteTeacherList'
import { Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, makeStyles, createStyles, Theme, TableContainer } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { fetchTeacher } from '../../../actions/remoteTeacher/remoteTeacherDetail'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      textAlign: 'center'
    },
    tableContainer: {
      maxHeight: '400px'
    },
    tableHead: {
      backgroundColor: '#F4F6F8',
    }
  }),
);

interface Props {
  assignation: Assignation
  remoteTeacherList?: RemoteTeacherListState;
}

const AssignationRemoteTeacherEdit: React.FC<Props> = ({ assignation, remoteTeacherList }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [selectedTeacher, setSelectedTeacher] = useState<RemoteTeacher | null>(null)

  const close = () => { setOpenConfirm(false) }

  const handleDismiss = () => { close() }

  const handleAccept = () => { setOpenConfirm(true) }

  const handleConfirm = () => {
    dispatch(updateAssignation({ ...assignation, teacher: selectedTeacher! }))
    close()
  }

  const handleRowClick = (teacher) => {
    dispatch(fetchTeacher(teacher.id, (response) => setSelectedTeacher(response)))
  }

  useEffect(() => {
    if (!remoteTeacherList?.isFetching) 
    {
      dispatch(fetchTeachersOrderedBySuggestion(assignation.teacher!.id, assignation.group.grade, assignation.slotTime, assignation.dayOfWeek))
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, assignation.group.grade, assignation.slotTime, assignation.teacher, assignation.dayOfWeek]);

  const assignatedGroupsPerMaxSlotsPreferedPercentage = (assignatedGroupsPerMaxSlotsPrefered) => assignatedGroupsPerMaxSlotsPrefered ? (assignatedGroupsPerMaxSlotsPrefered * 100).toFixed() : "0"

  return (<>
    <EditButtonDialog
      title="Sugerencias de Docentes Remotos"
      onAccept={handleAccept}
      disabled={selectedTeacher === null}
      icon={<SearchIcon fontSize="small" />}
    >
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead className={classes.tableHead}>
            <TableRow >
              <TableCell>Docente Remoto</TableCell>
              <TableCell>Nivel</TableCell>
              <TableCell>Restante de DP</TableCell>
              <TableCell>Porcentaje DDR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!remoteTeacherList?.isFetching && remoteTeacherList?.teachers?.map(teacher =>
              <TableRow key={teacher.id} selected={teacher && selectedTeacher ? teacher.id === selectedTeacher!.id : undefined} hover={true} onClick={() => handleRowClick(teacher)} style={{ cursor: 'pointer' }}>
                <TableCell>{teacher.fullName}</TableCell>
                <TableCell>{teacher.level}</TableCell>
                <TableCell>{teacher.assignatedGroupsMinusPedagogicalDesire}</TableCell>
                <TableCell>{assignatedGroupsPerMaxSlotsPreferedPercentage(teacher.assignatedGroupsPerMaxSlotsPrefered)}%</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

      </TableContainer>
      {remoteTeacherList?.isFetching &&
        <div className={classes.progress}>
          <CircularProgress size={100} />
        </div>
      }
    </EditButtonDialog>

    <ConfirmDialog
      open={openConfirm}
      title="Confirmar nuevo docente remoto"
      message={`¿Confirma que desea cambiar esta asignación a ${selectedTeacher?.fullName}? Tené en cuenta que esta acción cambiará todas las clases futuras de la asignación.`}
      onConfirm={handleConfirm}
      onDismiss={handleDismiss}
    />

  </>
  )
}

const mapStateToProps = (state: AppState) => ({
  remoteTeacherList: state.remoteTeacherList
})

export default connect(mapStateToProps, null)(AssignationRemoteTeacherEdit)