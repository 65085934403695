import { Button } from "@material-ui/core"
import Dialog, { DialogProps } from "@material-ui/core/Dialog"
import { default as DialogActions, default as DialogContent } from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { FC, useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import updateClass from '../../../actions/class/classUpdate'
import Checkbox from "../../../components/Checkbox"
import KeyboardTimePicker from "../../../components/KeyboardTimePicker"
import Selector from "../../../components/Selector"
import TextField from "../../../components/TextField"
import Class, { activityStates, activityStateToDescription, cancellationReasonDescription, phases, phaseToDescription, statusToDescription } from '../../../models/Class'
import Group from "../../../models/Group"
import School from "../../../models/School"
import { isValidSchoolTime, dateToString } from '../../../utils/days';
import { sortAlphabetically, sortByAdapter } from "../../../utils/StringUtils"
import useStyles from "./styles"
import AssignationSummary from "../../components/AssignationSummary";
import { DialogBasicProps } from "../../components/Utils/Dialogs/AnahiDialogIconButton";
import { AppProp, connectApp } from "../../../reducers"
import { Platform } from "../../../models/Platform"
import { equals, find } from "ramda"
import { Proposal } from "../../../models/Proposal"

type ClassReportProps = DialogBasicProps & { selectedClass: Class, disabled: boolean } & AppProp<'platformList' | 'proposalList'>

const preventBackdropClick = (onClose: () => void): DialogProps['onClose'] => (_event, reason) => {
  if (reason !== 'backdropClick') {
    onClose()
  }
}

const ClassReportDialog: FC<ClassReportProps> = ({ onClose, open, selectedClass, disabled, platformList, proposalList }) => {

  const stylesClasses = useStyles()

  const dispatch = useDispatch()

  const [updatedClass, setUpdatedClass] = useState<Class>(new Class(selectedClass))
  const [isTimeConfirmed, setIsTimeConfirmed] = useState<boolean>(updatedClass.isDictated())

  const setClass = (key: keyof Class) => (event: any) => {
    setUpdatedClassValues({ [key]: event.target.value })
  }

  const updateReferencedValue = (valueName: string) => (event: any) => {
    const updatedValue = { [valueName]: event.target.value, [valueName + 'Id']: event.target.value.id }
    setUpdatedClassValues(updatedValue)
  }

  const setUpdatedClassValues = (values: Partial<Class>) => {
    setUpdatedClass(new Class({ ...updatedClass, ...values }))
  }

  /*
    This is necessary because to validate that the initial value is a valid menu item Material UI uses reference equality to check if the initial
    value is in the menu items list. If we dont do this there would not be an initial value in the fields in which this is used.
  */
  const getValueFromReference = <T extends unknown>(valueToFind: T | undefined, values: T[]): T | undefined => find(equals(valueToFind))(values)

  const getPlatformReference = (platform?: Platform): Platform | undefined => getValueFromReference(platform, platformList.platforms || [])
  const getProposalReference = (proposal?: Proposal): Proposal | undefined => getValueFromReference(proposal, proposalList.proposals || [])

  useEffect(() => {
    setUpdatedClass(selectedClass)
  }, [dispatch, selectedClass])

  const cancellationReasons = (): string[] =>
    sortAlphabetically(updatedClass.possibleCancellationReasons(), cancellationReasonDescription)
  const group: Group = selectedClass.assignation.group
  const school: School = group.school

  return (
    <Dialog fullWidth scroll="body" maxWidth="sm" open={open} onClose={preventBackdropClick(onClose)}>

      <DialogTitle>Informe de Clase del {selectedClass.prettyDateTime()}</DialogTitle>

      <DialogContent dividers>
        <AssignationSummary
          department={school.department}
          grade={group.grade}
          schoolNumber={school.number}
          classroomTeacher={group.teacher}
        />

        <Selector
          label="Estado de la clase"
          disabled={disabled}
          menuItems={selectedClass.possibleStatuses()}
          onChange={setClass('status')}
          required={true}
          textAdapter={statusToDescription}
          value={updatedClass.status}
          variant="filled"
        />

        <KeyboardTimePicker
          label="Horario exacto en el cual inició la clase"
          date={updatedClass.sinceDateMoment()}
          disabled={disabled}
          hidden={updatedClass.isCancelled()}
          onChange={(date) => {
            if (date && date.isValid()) {
              // This solves a bug: if typing by hand the time,
              // when an INVALID time appears (e.g. 14:1 ) the date is set 
              // to today. So each time change we should make sure the
              // date is the same from the selected Class.
              updatedClass.sinceDate = dateToString(date.clone().dayOfYear(selectedClass.sinceDateMoment().dayOfYear()).year(selectedClass.sinceDateMoment().year()))
              setUpdatedClass(new Class(updatedClass))
              setIsTimeConfirmed(true)
            }
          }}
          required={true}
          validate={(date) => !!date && isValidSchoolTime(date)}
        />

        <Checkbox
          label="Confirmo que esa fue la hora a la que sucedió la clase"
          disabled={disabled}
          hidden={!updatedClass.isDictated()}
          onChange={(_event, checked) => setIsTimeConfirmed(checked)}
          required={true}
          value={isTimeConfirmed}
        />

        <Selector
          label="Plataforma utilizada"
          disabled={disabled}
          hidden={!updatedClass.isDictated()}
          menuItems={sortByAdapter(platformList.platforms || [], (platform) => platform.description)}
          onChange={updateReferencedValue('platform')}
          required={true}
          textAdapter={(platform: Platform) => platform.description}
          value={getPlatformReference(updatedClass.platform)}
          variant="filled"
        />

        <TextField
          label="Link a la captura de pantalla"
          disabled={disabled}
          hidden={!updatedClass.requiresScreenshot()}
          variant="filled"
          multiline={true}
          onChange={setClass('screenshot')}
          required={updatedClass.requiresScreenshot()}
          rows={1}
          value={updatedClass.screenshot}
        />

        <Selector
          label="Motivo"
          disabled={disabled}
          hidden={!updatedClass.isCancelled()}
          menuItems={cancellationReasons()}
          onChange={setClass('cancellationReason')}
          required={true}
          textAdapter={cancellationReasonDescription}
          value={updatedClass.cancellationReason}
          variant="filled"
        />

        <TextField
          label="Link de evidencia"
          disabled={disabled}
          hidden={!updatedClass.requiresEvidence()}
          variant="filled"
          multiline={true}
          onChange={setClass('screenshot')}
          required={updatedClass.requiresEvidence()}
          rows={1}
          value={updatedClass.screenshot}
        />

        <Checkbox
          label="¿Hubo cortes o problemas técnicos durante la VC?"
          disabled={disabled}
          hidden={!updatedClass.isDictated()}
          onChange={(_event, checked) => {
            updatedClass.hadIssuesDuringTheCall = checked
            setUpdatedClass(new Class(updatedClass))
          }}
          required={true}
          value={updatedClass.hadIssuesDuringTheCall}
        />

        <TextField
          label="Notas Técnicas"
          disabled={disabled}
          helperText="Si hubo problemas técnicos durante la VC (cortes, problemas de audio o imagen, etc.), detalle cómo fueron."
          hidden={!(updatedClass.isDictated() || updatedClass.requiresTechNotes())}
          variant="filled"
          multiline={true}
          onChange={setClass('technicalNotes')}
          required={updatedClass.requiresTechNotes()}
          rows={5}
          value={updatedClass.technicalNotes}
        />

        <Selector
          label="Propuesta"
          disabled={disabled}
          hidden={!updatedClass.isDictated()}
          menuItems={sortByAdapter(proposalList.proposals || [], (proposal) => proposal.description)}
          onChange={updateReferencedValue('proposal')}
          required={true}
          textAdapter={(proposal: Proposal) => proposal.description}
          value={getProposalReference(updatedClass.proposal)}
          variant="filled"
        />

        <Selector
          label="Etapa"
          disabled={disabled}
          hidden={!updatedClass.isDictated()}
          menuItems={phases}
          onChange={setClass("phase")}
          required={true}
          textAdapter={phaseToDescription}
          value={updatedClass.phase}
          variant="filled"
        />

        <Selector
          label="¿Se hizo la actividad de aula prevista?"
          disabled={disabled}
          hidden={!updatedClass.isDictated()}
          menuItems={activityStates}
          onChange={setClass('activityWasConcluded')}
          required={true}
          textAdapter={activityStateToDescription}
          value={updatedClass.activityWasConcluded}
          variant="filled"
        />

        <TextField
          label="Notas Pedagógicas"
          disabled={disabled}
          helperText="Desarrollo, detalles y recordatorios pedagógicos de los temas trabajados en clase."
          hidden={!updatedClass.isDictated()}
          variant="filled"
          multiline={true}
          onChange={setClass('pedagogicalNotes')}
          required={updatedClass.isDictated()}
          rows={5}
          value={updatedClass.pedagogicalNotes}
        />

        <TextField
          label="Acuerdos con DA"
          disabled={disabled}
          variant="filled"
          multiline={true}
          onChange={setClass('agreements')}
          required={true}
          rows={5}
          value={updatedClass.agreements}
          helperText="Acuerdos con DA referidos al desarrollo de las propuestas y etapas, según el transcurso de cada clase."
        />

        <TextField
          label="Notas Complementarias"
          disabled={disabled}
          helperText="Situaciones complementarias o paralelas al desarrollo pedagógico de la clase. Por ejemplo: La DA se ausentó en la mitad, un niñx tiene inconvenientes personales, hubo una pelea entre lxs niñxs, algunas computadoras no andan, etc."
          variant="filled"
          multiline={true}
          onChange={setClass('complementaryNotes')}
          rows={5}
          value={updatedClass.complementaryNotes}
        />

      </DialogContent>

      <DialogActions>
        {!disabled &&
          <Button
            className={stylesClasses.button}
            variant="contained"
            color="primary"
            size="small"
            disabled={!updatedClass.isValid() || (updatedClass.needsTimeConfirmation() && !isTimeConfirmed)}
            onClick={() => { onClose(); dispatch(updateClass(updatedClass)) }}>
            Aceptar
          </Button>}

        <Button
          className={stylesClasses.button}
          variant="contained"
          color="primary"
          size="small"
          onClick={onClose}>
          {disabled ? "Cerrar" : "Cancelar"}
        </Button>
      </DialogActions>

    </Dialog>
  )

}


export default connectApp('platformList', 'proposalList')(ClassReportDialog)

