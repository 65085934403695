import moment, { Moment } from "moment"
import { isNil, without } from "ramda"
import { isInFuture, isInThisWeek } from '../../utils/days'
import { literalStrings } from '../../utils/StringUtils'
import Assignation from "../Assignation"
import { Platform } from "../Platform"
import { Proposal } from "../Proposal"
import RemoteTeacher from "../RemoteTeacher"
import { CancelledByRemoteTeacher, CancelledWithAnticipation, CancelledWithoutAnticipation, ClassStatusState, Dictated, CancelledByExternalFactors, Pending } from "./StatusState"

export default class Class {

  readonly id!: number
  sinceDate!: string
  untilDate!: string
  kind!: Kind
  status!: ClassStatus
  cancellationReason?: CancellationReason
  phase?: string
  activityWasConcluded?: string
  hadIssuesDuringTheCall?: boolean
  technicalNotes?: string
  pedagogicalNotes?: string
  sentToCeibal!: boolean
  teacherId!: number
  assignationId!: number
  rescheduledToId?: number
  assignation!: Assignation
  teacher?: RemoteTeacher
  platformId?: number
  platform?: Platform
  screenshot?: string
  proposalId?: number
  proposal?: Proposal
  agreements?: string
  complementaryNotes?: string

  constructor(props?: Partial<Class>) {
    Object.assign(this, props)
  }

  canBeRescheduled(): boolean {
    return this.isCancelled() && !this.isRescheduled()
  }

  canBeEdited(): boolean {
    return !this.isRescheduled() &&
      (this.isInFuture() ||
        (isInThisWeek() && this.isInThisWeek()))
  }

  canBeReported(): boolean {
    return this.isPending() && this.canBeEdited()
  }

  isCancelled(): boolean {
    return this.status.startsWith('Cancelled')
  }

  isDictated(): boolean {
    return this.status === 'Dictated'
  }

  isPending(): boolean {
    return this.status === 'Pending'
  }

  isInThisWeek(): boolean {
    return isInThisWeek(this.sinceDate)
  }

  isInFuture(): boolean {
    return isInFuture(this.sinceDate)
  }

  isRescheduled(): boolean {
    return !isNil(this.rescheduledToId)
  }

  sinceDateMoment(): Moment {
    return moment(this.sinceDate)
  }

  prettyDateTime(): string {
    return this.sinceDateMoment().format('dddd D MMM HH:mm') + "hs"
  }

  possibleStatuses(): ClassStatus[] {
    var statuses: ClassStatus[] = classStatuses

    if (this.isInFuture() && !this.isInThisWeek()) {
      statuses = without(['Dictated'], statuses)
    }
    if (this.isCancelled() || this.isDictated()) {
      statuses = without(['Pending'], statuses)
    }
    return statuses
  }

  possibleCancellationReasons(): CancellationReason[] {
    return this.statusState().cancellationReasons()
  }

  requiresTechNotes(): boolean {
    return this.statusState().requiresTechNotes()
  }

  requiresScreenshot(): boolean {
    return this.statusState().requiresScreenshot()
  }

  requiresEvidence(): boolean {
    return this.statusState().requiresEvidence()
  }

  isValid(): boolean {
    return this.statusState().isValid()
  }

  needsTimeConfirmation(): boolean {
    return this.statusState().needsTimeConfirmation()
  }

  statusState(): ClassStatusState {
    switch (this.status) {
      case 'CancelledByRemoteTeacher': return new CancelledByRemoteTeacher(this)
      case 'CancelledBySchoolWITHAnticipation': return new CancelledWithAnticipation(this)
      case 'CancelledBySchoolWITHOUTAnticipation': return new CancelledWithoutAnticipation(this)
      case 'CancelledByExternalFactors': return new CancelledByExternalFactors(this)
      case 'Dictated': return new Dictated(this)
      default: return new Pending(this)
    }
  }

}

export type ClassStatus = typeof classStatuses[number]

export const classStatuses = literalStrings(
  "Pending",
  "Dictated",
  "CancelledByRemoteTeacher",
  "CancelledBySchoolWITHAnticipation",
  "CancelledBySchoolWITHOUTAnticipation",
  "CancelledByExternalFactors"
)

export const statusToDescription = (status: ClassStatus): string => {
  switch (status) {  // DUPLICATED IN BACKEND. WHEN CHANGED UPDATE BOTH PLEASE
    case 'Pending': return "Pendiente"
    case 'Dictated': return "Dictada"
    case 'CancelledByRemoteTeacher': return "Cancelada por el Docente Remoto"
    case 'CancelledBySchoolWITHAnticipation': return "Cancelada por la escuela CON anticipación"
    case 'CancelledBySchoolWITHOUTAnticipation': return "Cancelada por la escuela SIN anticipación"
    case 'CancelledByExternalFactors': return "Cancelada por factores externos"
  }
}

export type Kind = typeof kinds[number]

export const kinds = literalStrings(
  "O",
  "RxDR",
  "RxEsc"
)

export const kindsToDescription = (type: Kind): string => {
  switch (type) {  // DUPLICATED IN BACKEND. WHEN CHANGED UPDATE BOTH PLEASE
    case 'O': return "Original"
    case 'RxDR': return "Reprogramada por el Docente Remoto"
    case 'RxEsc': return "Reprogramada por la Escuela"
  }
}

export type ActivityState = typeof activityStates[number]

export const activityStates = literalStrings(
  "Yes",
  "No",
  "Partially",
)

export const activityStateToDescription = (status: ActivityState): string => {
  switch (status) {
    case 'Yes': return "Si"
    case 'No': return "No"
    case 'Partially': return "Parcialmente"
  }
}

export type Phase = typeof phases[number]

export const phases = literalStrings(
  "Zero",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "ProposalClosure"
)

export const phaseToDescription = (phase: Phase): string => {
  switch (phase) {
    case 'Zero': return '0'
    case 'One': return '1'
    case 'Two': return '2'
    case 'Three': return '3'
    case 'Four': return '4'
    case 'Five': return '5'
    case 'Six': return '6'
    case 'Seven': return '7'
    case 'Eight': return '8'
    case 'Nine': return '9'
    case 'Ten': return '10'
    case 'ProposalClosure': return 'Cierre de propuesta'
  }
}

export type CancellationReason = typeof cancellationReasons[number]

export const cancellationReasons = literalStrings(
  "TechnicalIssuesConnectivity",
  "TechnicalIssuesRT",
  "TechnicalIssuesVideoConference",
  "TechnicalIssuesUnknown",
  "HealthOrPersonalIssuesRT",
  "HealthOrPersonalIssuesCT",
  "NonSchoolDay",
  "ExtracurricularActivity",
  "Strike",
  "VideoConferenceRoomBusy",
  "WorksAtSchool",
  "RackKeyNotFound",
  "NoAnswer",
  "PowerCutAtSchool",
  "ClimateFactorsOrFewStudentsInTheClassroom",
  "Incident",
  "BiweeklyGroup",
  "Holiday"
)

export const reasonToShortDescription = (reason: CancellationReason): string => {
  switch (reason) {
    case 'TechnicalIssuesConnectivity': return "ProTec Conectividad"
    case 'TechnicalIssuesRT': return "ProTec DR"
    case 'TechnicalIssuesVideoConference': return "ProTec - VC"
    case 'TechnicalIssuesUnknown': return "ProTec - Desconocidas"
    case 'HealthOrPersonalIssuesRT': return "Enfermedad DR"
    case 'HealthOrPersonalIssuesCT': return "Enfermedad DA"
    case 'NonSchoolDay': return "Feriado o paro"
    case 'ExtracurricularActivity': return "Actividad extra"
    case 'Strike': return "Paro"
    case 'VideoConferenceRoomBusy': return "Sala VC ocupada"
    case 'WorksAtSchool': return "Obras"
    case 'RackKeyNotFound': return "Rack"
    case 'NoAnswer': return "Sin respuesta"
    case 'PowerCutAtSchool': return "Corte de luz"
    case 'ClimateFactorsOrFewStudentsInTheClassroom': return "Factores climáticos"
    case 'Incident': return "Inundación, robo o incendio"
    case 'BiweeklyGroup': return "Grupo quincenal"
    case 'Holiday': return "Feriado"
  }
}

export const reasonToDescription = (reason: CancellationReason): string => {
  switch (reason) {  // DUPLICATED IN BACKEND. WHEN CHANGED UPDATE BOTH PLEASE
    case 'TechnicalIssuesConnectivity': return "Problemas técnicos - Conectividad"
    case 'TechnicalIssuesRT': return "Problemas técnicos - del Docente Remoto"
    case 'TechnicalIssuesVideoConference': return "Problemas técnicos - Videoconferencia"
    case 'TechnicalIssuesUnknown': return "Problemas técnicos - Causas desconocidas"
    case 'HealthOrPersonalIssuesRT': return "Enfermedad o problemas personales del Docente Remoto"
    case 'HealthOrPersonalIssuesCT': return "Enfermedad o problemas personales del Docente de Aula"
    case 'NonSchoolDay': return "Feriado o paro en su localidad"
    case 'ExtracurricularActivity': return "Actividad extra curricular (paseo jornadas especiales etc.)"
    case 'Strike': return "Paro de actividades"
    case 'VideoConferenceRoomBusy': return "Salón de video conferencias ocupado"
    case 'WorksAtSchool': return "Obras en la escuela"
    case 'RackKeyNotFound': return "No se encuentra la llave del rack"
    case 'NoAnswer': return "Sin respuesta se desconoce el motivo de cancelación"
    case 'PowerCutAtSchool': return "Corte de energía eléctrica en la escuela"
    case 'ClimateFactorsOrFewStudentsInTheClassroom': return "Factores climáticos/ pocos alumnos en el aula"
    case 'Incident': return "Inundación, robo o incendio"
    case 'BiweeklyGroup': return "Grupo quincenal"
    case 'Holiday': return "Feriado"
  }
}

export const isDescriptionStatus = (description: string, status: ClassStatus) => description === statusToDescription(status)

export const cancellationReasonDescription = (cancelationReason: String) => reasonToDescription(cancelationReason as CancellationReason)