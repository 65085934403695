import React, { useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, CircularProgress } from '@material-ui/core'

export interface AutocompleteAsyncMultipleTextFieldProps<Entity> {
  label: string
  search: (input: string) => Promise<Entity[]>
  selectLabel: (option: Entity) => string
  onSelect: (option: Entity[] | null) => void
  newEntity?: (input: string) => Entity
  inputValue?: string
  disableClearable?: boolean
  controlledLength?: number
}


const AutocompleteAsyncMultipleTextField = <T extends {}>({ label, search, selectLabel, onSelect, newEntity, inputValue, disableClearable, controlledLength }: AutocompleteAsyncMultipleTextFieldProps<T>) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<T[]>([])
  const [loading, setLoading] = useState(false)
  const [resetKey, setResetKey] = useState(0)

  const fetchOptions = async (input: string) => {
    if (open) {
      setLoading(true)
      const searchedOptions = await search(input)
      setOptions(searchedOptions ? searchedOptions : [])
      setLoading(false)
    }
  }

  //This is needed to reset the value selected to null when the filters are cleaned
  useEffect(()=>{
    if(!inputValue) setResetKey(prev => prev + 1)
  }, [inputValue])

  return (
    <Autocomplete
      multiple
      disableClearable={disableClearable}
      open={open}
      key={resetKey}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => selectLabel(option) === selectLabel(value)}
      getOptionLabel={selectLabel}
      options={options}
      loading={loading}
      style={{minWidth: 200}}
      onChange={(_event, value) => { onSelect(value) }}
      onInputChange={(_event, newInputValue) => {
        if( !controlledLength || ( newInputValue && newInputValue.length >= controlledLength ) )
          fetchOptions(newInputValue)
      }}
      filterOptions={(options, params) => {
        // Suggest the creation of a new value
        if (newEntity && !options.length && params.inputValue) {
          options.push(newEntity(params.inputValue))
        }
        return options
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          //onChange={(event) => { fetchOptions(event.target.value) }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default AutocompleteAsyncMultipleTextField