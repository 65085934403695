export default class Teacher {

    id!: number
    firstName!: string
    fullName!: string
    lastName!: string
    phoneNumber!: string
    fullPhoneNumber!: string
    documentNumber!: string
    level!: number

    constructor(props?: Partial<Teacher>) {
        Object.assign(this, props)
    }

}