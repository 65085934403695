import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchClasses } from '../../../actions/class/classList'
import ClassesList from '../../components/ClassesList'
import { FilterKind, FilterSendToCeibal, FilterMultipleRemoteTeacher } from '../../components/Utils/Filters/index'
import { columns } from '../../../components/ClassListTable/ClassListColumns'

const { department, school, group, day, date, time, remoteTeacher, kind, status, cancellationReason, sentToCeibal, reportButton } = columns

const AdminClassesList: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchClasses(history.location.search))
  }, [dispatch, history.location.search])

  return <ClassesList
    columns={[department, school, group, day, date, time, remoteTeacher, kind, status, cancellationReason, sentToCeibal(useDispatch()), reportButton]}
  >   
    <FilterMultipleRemoteTeacher label='Docente Remoto' name='fullName' />
    <FilterMultipleRemoteTeacher label='Responsable de Gestión' name='managementManager' />
    <FilterSendToCeibal />
    <FilterKind />
  </ClassesList>
}


export default AdminClassesList