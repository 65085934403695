import React from "react"
import { Container } from "@material-ui/core"
import { AppProp, connectApp } from '../../../reducers'
import { isAdmin, isReferent } from '../../../reducers/auth/session'
import { ExportReportCard } from "../Utils/ExportReportsCard/ExportReportsCard"
import DownloadClasses from "./DownloadClasses"
import JobList from "./JobList"

const DownloadReports = ({ session }: AppProp<'session'>) =>
  <Container>
    <DownloadClasses />
    <DownloadAssignations session={session} />
    <DownloadRemoteTeacherAvailability session={session} />
    <DownloadGroupsByRemoteTeacher session={session} />
    <JobList />
  </Container>

const DownloadAssignations = ({ session }: AppProp<'session'>) => {
  const shouldShowAssignations = isAdmin(session) || isReferent(session)
  const prefix = isAdmin(session) ? '' : 'references/'

  return shouldShowAssignations ?
    <ExportReportCard
      title={'Reporte de asignaciones'}
      description={'Exportar un reporte de todas las asignaciones'}
      url={`${prefix}assignations/export`} />
    : <></>
}

const DownloadRemoteTeacherAvailability = ({ session }: AppProp<'session'>) => {

  return isAdmin(session) ?
    <ExportReportCard
      title={'Disponibilidad de Docentes Remotos'}
      description={'Exportar la disponibilidad horaria de todos los docentes remotos'}
      url={`availability-slots/export`}
    />
    : <></>
}

const DownloadGroupsByRemoteTeacher = ({ session }: AppProp<'session'>) => {

  return isAdmin(session) ?
    <ExportReportCard
      title={'Grupos por Docente Remoto'}
      description={'Exportar un reporte de todos los grupos por docentes remotos'}
      url={`remote-teachers/groups/export`}
    />
    : <></>
}

const ConnectedDownloadReports = connectApp('session')(DownloadReports)

export default ConnectedDownloadReports