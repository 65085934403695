import React, { FC, useEffect, useState } from "react"
import { getToken } from '../../../../utils/fetchApi'
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import useStyles from "../../Utils/ExportReportsCard/styles"
import { connect, useDispatch } from "react-redux"
import { pushAlert } from "../../../../actions/alert"
import { DeleteOutlined, GetAppOutlined } from '@material-ui/icons'
import { AppState } from "../../../../reducers"
import { JobListState } from "../../../../reducers/job/jobList"
import Job from "../../../../models/Job"
import { fetchJobs } from "../../../../actions/job/jobList"
import moment from "moment"
import { deleteJob } from "../../../../actions/job/jobDeleteOrUpsert"

interface JobProps {
  jobs: JobListState
}

interface JobDialogProps {
  rows: Job[];
  loading: boolean;
  isOpen: boolean
  onDownload: (jobId: string) => void
  onDelete: (jobId: string) => void
  onCancel: () => void
}

const JobDialog: React.FC<JobDialogProps> = (props) => {
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason !== "backdropClick") {
      props.onCancel && props.onCancel();
    }
  };

  //const available = (status: string) => status === 'completado'

  return (
    <>
      <Dialog
        open={props.isOpen}
        maxWidth="lg"
        disableRestoreFocus
        onClose={handleClose}
      >
        <DialogTitle id="draggable-dialog" style={{ cursor: 'auto', fontWeight: 'bold', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Listado de CSV Solicitados
        </DialogTitle>
        <DialogContent>
          {!props.loading &&
            <Box textAlign="center">
              {props.rows &&
                <Box style={{ justifyContent: 'center' }}>
                  <Table size="medium" stickyHeader={true}>
                    <TableHead >
                      <TableRow selected={true}>
                        <TableCell>Tipo</TableCell>
                        <TableCell >Archivo CSV</TableCell>
                        <TableCell>Creado el</TableCell>
                        <TableCell align='center'>Estado</TableCell>
                        <TableCell align='center'></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.rows.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {row.fileTypeName}
                            </TableCell>
                            <TableCell>
                              {row.fileName}
                            </TableCell>
                            <TableCell>
                              {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                            </TableCell>
                            <TableCell align='center'>
                              {row.statusName}
                            </TableCell>
                            {row.downloadable ?
                              <TableCell align='center' style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); props.onDownload(row.jobId) }}>
                                <GetAppOutlined />
                              </TableCell> :
                              row.failed ?
                                <TableCell align='center' style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); props.onDelete(row.jobId) }}>
                                  <DeleteOutlined />
                                </TableCell> : <TableCell></TableCell>}
                          </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                </Box>}
            </Box>
          }
          {
            props.loading &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={25} />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.onCancel && <Button
              style={{ marginRight: '8px' }} variant="contained" color="primary" size="small"
              onClick={() => props.onCancel && props.onCancel()}>Cerrar</Button>}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}


const JobList: FC<JobProps> = ({ jobs }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchJobs());
    setRefresh(false)
  }, [dispatch, refresh]);

  useEffect(() => {
    if (showDialog && !jobs.isFetching && jobs.jobs?.length === 0) {
      setShowDialog(false);
    }
  }, [jobs, showDialog]);


  const handleDeleteJob = async (jobId: string) => {
    dispatch(deleteJob(jobId))
    setRefresh(true)
  }

  const getCsv = async (jobId: string) => {
    const handleResponse = async (dispatch: any, response: any) => {
      if (response.error) {
        dispatch(pushAlert(response.error, true))
        return
      }
      if (response.message) {
        dispatch(pushAlert(response.message))
        return
      }

      if (!response.ok) {
        dispatch(pushAlert('Error al obtener el archivo'))
        return
      }

      const contentType = response.headers.get('Content-Type');

      if (contentType === 'text/csv') {
        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        const fileNameMatch = contentDisposition?.match(/filename="(.+)"/);
        const fileName = fileNameMatch ? fileNameMatch[1] : 'archivo.csv';

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        setRefresh(true)
      } else {
        const jsonResponse = await response.json();
        dispatch(pushAlert(jsonResponse.message || jsonResponse.error));
      }
    };

    const response = await fetch(`${window.config.apiURL}/jobs/${jobId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getToken()}`,
        'Accept': '*/*',
      }
    })

    await handleResponse(dispatch, response)

  }

  return (
    <Container>
      <Paper square className={classes.paper}>
        <Typography variant="h5">
          Listado de CSV Solicitados
        </Typography>
        <Typography variant="body1">
          Permite visualizar el estado de los archivos CSV solicitados y descargarlos
          <Button variant='contained' className={classes.download}
            disabled={jobs.isFetching || jobs.jobs?.length === 0}
            onClick={() => { setRefresh(true); setShowDialog(true) }}>
            Ver lista
          </Button>
        </Typography>
        {showDialog &&
          <JobDialog isOpen={showDialog}
            rows={jobs.jobs || []}
            loading={jobs.isFetching}
            onDownload={getCsv}
            onDelete={handleDeleteJob}
            onCancel={() => setShowDialog(false)}
          />}
      </Paper>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => ({
  jobs: state.jobList
})

export default connect(mapStateToProps)(JobList);
