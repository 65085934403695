export interface Field {
  name: string;
  display: string;
}

export const parse = (csv: string, fields: Field[]) => {
  let lines = csv.split('\n')
  lines.shift()
  const records = [] as any[]
  for (const line of lines) {
    const values = line.split(',');
    const record = {};
    for (let i = 0; i < values.length; i++) {
      if (fields[i] && values[i] && values[i].length) {
        appendAttribute(record, fields[i].name, values[i])
      }
    }
    records.push(record)
  }
  return records
}

/** 
 * Takes the value and puts it in the data where the field indicates.
 * Creates the intermediate objects needed. Example:
 * ```
 * appendAttribute( {}, "car.brand.name", "fiat" )
 * ```
 * returns:
 * ```
 *  { car: {brand: {name: "fiat"}}}
 * ```
 * @param data The object where the value will be put.
 * @param field The path to save in.
 * @param value The value to save.
 * @returns The object data with the new attribute.
 */
export const appendAttribute = (data: any, field: string, value: any) => {
  const path = field.split('.')
  const lastPath = path.pop()!
  const entity = path.reduce((obj, attr) => obj[attr] || (obj[attr] = {}), data)
  entity[lastPath] = typeof value === "string" ? escapeCharacters(value) : value
  return data
}

/** 
 * Traverses the data object tree accessing the attribute indicated by the point-separated string path. Example:
 * ```
 * getAttribute("car.brand.name", { car: {brand: {name: "fiat"}}})
 * ```
 * returns:
 * ```
 * "fiat"
 * ```
 * @param data The object where the value will be taken from.
 * @param path The point-separated string indicating the path.
 * @returns the value in the attribute, or undefined if any intermediate object doesn't exist.
 */
export const getAttribute = (path: string, data: any) =>
  path.split('.').reduce((value, attr) => value ? value[attr] : undefined, data)

const escapeCharacters = (value: string): string => {
  return value.replace(/\r?\n|\r/gm, '');
}
