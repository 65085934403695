import React from "react"
import fetchApi, { getToken } from '../../../../utils/fetchApi'
import { Button } from "@material-ui/core"
import { AppProp, connectApp } from '../../../../reducers'
import { isAdmin, isReferent } from '../../../../reducers/auth/session'
import DatePickerButton, { DateIntervalButtonProps } from "../../Utils/DatePickerButton"
import useStyles from "../../Utils/ExportReportsCard/styles"
import { useDispatch } from "react-redux"
import { pushAlert } from "../../../../actions/alert"
import { fetchJobs } from "../../../../actions/job/jobList"

type ExportButtonProps = DateIntervalButtonProps & AppProp<'session'>
const ExportClassesButton = ({ dateInterval, session }: ExportButtonProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const prefix = isAdmin(session) ? '' : isReferent(session) ? 'references/' : 'remote-teachers/'

  const getClasses = async () => {
    const handleResponse = (dispatch: any, response: any) => {
      if (response.error) {
        dispatch(pushAlert(response.error, true))
      }
      if (response.message) {
        dispatch(pushAlert(response.message))
        dispatch(fetchJobs());
      }
    }

    const response = await fetchApi(getToken(), 'GET', `${prefix}classes/export?from=${dateInterval.from.format()}&to=${dateInterval.to.format()}`)

    handleResponse(dispatch, response)

  }


  return (
      <Button variant='contained' className={classes.download}
        onClick={() => dateInterval.from && dateInterval.from.isValid() && dateInterval.to && dateInterval.to.isValid() ? getClasses() :
          dispatch(pushAlert('Verifique las Fechas', true))}>
        Solicitar csv
      </Button>
  )
}

const ConnectedExportButton = connectApp('session')(ExportClassesButton)

export default () => <DatePickerButton title='Reporte de clases dictadas' Button={ConnectedExportButton} />