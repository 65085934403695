import React from 'react'
import AssignationNavItem from './AssignationNavItem'
import AdminWrapper from '../AdminWrapper'
import AdminNavItem from './AdminNavItem'
import { defaultFilters } from '../../components/ClassesList'
import { filtersToSearch } from '../../components/Utils/Filters/Filter'
import DownloadReportNavItem from '../../DownloadReportNavItem'

const AdminNavItems: React.FC = () =>
  <AdminWrapper>
    <AdminNavItem
      uri='schools'
      title='Escuelas'
      icon='apartment'
    />

    <AdminNavItem
      uri='school-contacts'
      title='Contactos escolares'
      icon='contacts'
    />

    <AdminNavItem
      uri='school-jabber-users'
      title='Usuarios jabber'
      icon='vpn_key'
    />

    <AdminNavItem
      uri='non-school-days'
      title='Fechas importantes'
      icon='today'
    />

    <AdminNavItem
      uri='remote-teachers'
      title='Docentes remotos'
      icon='school'
    />

    <AssignationNavItem />

    <AdminNavItem
      uri={`classes?${filtersToSearch(defaultFilters)}`}
      title='Clases'
      icon='event'
    />

    <DownloadReportNavItem base='admin' />

    <AdminNavItem
      uri='settlement'
      title='Liquidaciones'
      icon='attach_money'
    />

    <AdminNavItem
      uri='settlement/records'
      title='Historial de envios'
      icon='assignment'
      nested
    />

    <AdminNavItem
      uri='configuration'
      title='Configuración general'
      icon='settings'
    />
  </AdminWrapper>


export default AdminNavItems