import React from 'react'
import Assignation from '../../../models/Assignation'
import EntityDetails from '../Utils/EntityDetails'
import AssignationRemoteTeacherEdit from './AssignationRemoteTeacherEdit'
import AssignationRemoteTeacherSuggestion from './AssignationRemoteTeacherSuggestion'

interface AssignationRemoteTeacherProps {
  assignation: Assignation
  disabled: boolean
}

const AssignationRemoteTeacher: React.FC<AssignationRemoteTeacherProps> = ({ assignation, disabled }) => (<>
  <EntityDetails
    title="Docente Remoto"
    entity={assignation.teacher}
    rows={[
      ['DNI', 'documentNumber'],
      ['Nombre', 'firstName'],
      ['Apellido', 'lastName'],
      ['Email', 'email'],
      ['Teléfono', 'phoneNumber'],
    ]}
    gadgets={!disabled && <>
      <AssignationRemoteTeacherSuggestion assignation={assignation} />
      <AssignationRemoteTeacherEdit {...assignation} />
    </>}
  />
</>
)

export default AssignationRemoteTeacher

