import { Button, Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { pushAlert } from "../../../../actions/alert";
import { fetchJobs } from "../../../../actions/job/jobList";
import fetchApi, { getToken } from "../../../../utils/fetchApi";

interface ExportReportCardProps {
  title: string,
  description: string,
  url: string
}

export const ExportReportCard = (props: ExportReportCardProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()


  const getReport = async () => {
    const handleResponse = (dispatch: any, response: any) => {
      if (response.error) {
        dispatch(pushAlert(response.error, true))
      }
      if (response.message) {
        dispatch(pushAlert(response.message))
        dispatch(fetchJobs());
      }
    }

    const response = await fetchApi(getToken(), 'GET', props.url)

    handleResponse(dispatch, response)

  }

  return (
    <Container>
      <Paper square className={classes.paper}>
        <Typography variant="h5">
          {props.title}
        </Typography>
        <p>
          {props.description}
          <Button variant='contained' className={classes.download}
            onClick={getReport}>
            Solicitar csv
          </Button>

        </p>
      </Paper>
    </Container>
  )
}